<template>
  <Teleport to="body">
    <div class="alert" :class="`alert--${type}`" :style="{ top: containsHeader ? '44px' : '0' }">
      <div class="alert__content">
        <Usp
          v-if="typeof alertText === 'string'"
          :text="alertText"
          :icon="props.type === 'ok' ? 'check' : 'close'" />
        <div v-else class="alert__list">
          <ul>
            <li v-for="(error, index) in alertText" :key="`error-${index}`">{{ error }}</li>
          </ul>
        </div>
        <NuxtLink v-if="link" :to="link" class="alert__link" @click="store.isOpen = false">
          {{ contentStore.content?.see }}
        </NuxtLink>
      </div>
      <div class="progress active"></div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import type { AlertType } from '@/types/alert';

const store = useAlertStore();

export interface AlertProps {
  type?: AlertType;
  alertText: string | string[] | Record<string, string>;
  link?: string;
  duration?: number;
}

const props = withDefaults(defineProps<AlertProps>(), {
  type: 'ok',
  link: '',
  duration: 5,
});

const contentStore = useContentStore();

const animationTime = `${props.duration}s`;

onMounted(() => {
  setTimeout(() => {
    store.isOpen = false;
  }, props.duration * 1000);
});

const containsHeader = document.body.contains(document.getElementById('header'));
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  z-index: 50;
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $size-sm;
    height: px(60);
    color: $c-white;
    background-color: $c-green-3;
    font-weight: 700;

    &:has(.alert__list) {
      height: auto;
      min-height: px(60);
    }
  }

  &__list {
    ul {
      margin: 0;
      padding: 0 0 0 px(20);
    }

    li {
      margin-bottom: px(8);
    }
  }

  &__link {
    font-size: px(14);
    padding-right: px(4);
    color: $c-white;
    text-decoration: underline;
  }

  &--warn {
    .alert__content {
      background-color: $c-orange-1;
      color: $c-white;
    }
  }

  &--error {
    .alert__content {
      background-color: $c-red-1;
      color: $c-white;
    }
  }
}

.alert.active {
  transform: translateX(0%);
}

.alert .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: px(8);
  width: 100%;
}

.alert .progress::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: $c-white;
}

.progress.active::before {
  animation: progress #{v-bind(animationTime)} linear forwards;
}

@keyframes progress {
  100% {
    right: 100%;
  }
}
</style>
